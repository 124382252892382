import { Button } from 'components/Phantom/Button'
import { CSSProperties, FC, useRef, useState } from 'react'
import { BarProps, GraphProps, PersonalSleepProps } from './types'
import styles from './PersonalSleep.module.scss'
import cx from 'classnames'
import { Icon } from 'components/Phantom/Icon'
import { getGraphData, getNumColumns, getNumRows } from 'components/Phantom/_sections/PersonalSleep/utils'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TempDialAnimateUp } from 'components/Phantom/TempDial'
import { VideoGif } from 'components/basic/VideoGif'
import { useRefreshScrollTrigger } from 'components/_hooks/useRefreshScrollTrigger'
import { amClickedButton } from 'events/amplitude'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Type } from 'components/Type'

export const PersonalSleepConnected = observer((props: Partial<PersonalSleepProps>) => {
	const { settingsStore } = useRootStore()
	return (
		<PersonalSleep
			{...props}
			metric={settingsStore.metricRegion}
		/>
	)
})

export const PersonalSleep: FC<PersonalSleepProps> = (props) => {
	const {
		metric = false,
		cta = { href: '/pod-cover', text: 'See how it works' },
		anchorHref,
		showExtraSleep = true,
		eyebrow,
		header = 'We make sleep personal',
		subHeader = 'The Pod personalizes your sleep setup with Autopilot to create the ultimate sleeping environment for deeper and higher quality sleep.',
		theme = 'dark',
	} = props

	const containerRef = useRef(null)

	useRefreshScrollTrigger(containerRef)

	return (
		<>
			<section
				className={styles.container}
				ref={containerRef}
				data-theme={theme}
				id={'personal-sleep'}
			>
				<header className={styles.header}>
					<Type.Eyebrow
						animateOnScroll
						className={styles.eyebrow}
					>
						{eyebrow}
					</Type.Eyebrow>
					<Type.Headline2 animateOnScroll>{header}</Type.Headline2>
					<Type.Body1
						animateOnScroll
						className={styles.subheader}
					>
						{subHeader}
					</Type.Body1>
					{theme === 'dark' ? (
						<Button.White
							id={'personal_sleep_cta'}
							className={styles.header_cta}
							href={cta.href}
						>
							{cta.text}
						</Button.White>
					) : (
						<Button.Dark
							id={'personal_sleep_cta'}
							className={styles.header_cta}
							href={cta.href}
						>
							{cta.text}
						</Button.Dark>
					)}
				</header>
				<article className={styles.perfect_temperature}>
					<div className={styles.main_image_container}>
						<VideoGif
							src={'https://d115sb6i1ixllw.cloudfront.net/assets/sleep_personal_hi_res.mp4'}
							className={styles.main_image}
						/>
						<TempDialAnimateUp
							endTemp={65}
							metric={metric}
							text={'Their side'}
							className={styles.cool}
						/>
						<TempDialAnimateUp
							endTemp={78}
							metric={metric}
							text={'Your side'}
							className={styles.warm}
						/>
					</div>
					<ul className={styles.benefit_container}>
						<li>
							<Icon
								name={'TemperatureLight'}
								color={theme === 'dark' ? 'white' : 'black'}
								size={36}
							/>
							<Type.Headline4 as={'h3'}>The perfect temperature all night</Type.Headline4>
							<Type.Body1>Your body temperature changes throughout the night. Autopilot heats and cools your bed to match your body's natural rhythm, keeping you in deep sleep for longer.</Type.Body1>
						</li>
						<li>
							<Icon
								name={'ElevationLight'}
								color={theme === 'dark' ? 'white' : 'black'}
								size={36}
							/>
							<Type.Headline4 as={'h3'}>The ideal elevation for optimal sleep</Type.Headline4>
							<Type.Body1>Pod 4 Ultra makes micro-adjustments to your elevation while you sleep, improving your breathing, reducing pressure, and maximizing overall comfort.</Type.Body1>
						</li>
					</ul>
				</article>
			</section>
			{showExtraSleep && (
				<ExtraSleep
					ctaText={cta.text}
					ctaUrl={cta.href}
					anchorHref={anchorHref}
				/>
			)}
		</>
	)
}

export const ExtraSleep = ({ ctaText, ctaUrl, anchorHref }: { ctaText?: string; ctaUrl?: string; anchorHref?: string }) => {
	const cta = anchorHref ? (
		<AnchorLink
			offset={50}
			href={anchorHref}
			className={styles.extra_sleep_cta}
		>
			<Button.White
				id={'personal-sleep-cta'}
				onClick={() => {
					amClickedButton('Personal sleep CTA', 'personal-sleep-cta')
				}}
			>
				{ctaText ?? 'Shop now'}
			</Button.White>
		</AnchorLink>
	) : (
		<Button.White
			id={'personal-sleep-cta'}
			href={ctaUrl}
			className={styles.extra_sleep_cta}
		>
			{ctaText ?? 'Shop now'}
		</Button.White>
	)

	return (
		<section className={styles.extra_sleep}>
			<div className={styles.extra_sleep_inner}>
				<header className={styles.science_header}>
					<Type.Eyebrow className={styles.eyebrow}>The science</Type.Eyebrow>
					<Type.Headline2>The Pod gives you up to one more hour of sleep every night</Type.Headline2>

					{cta}
				</header>

				<Graph data={getGraphData()} />
			</div>
		</section>
	)
}

const Graph: FC<GraphProps> = (props) => {
	const [highlighted, setHighlighted] = useState<number | null>(0)

	const highlightedBar = props.data[highlighted]

	const numColumns = getNumColumns(props.data)
	const numRows = getNumRows(props.data)
	return (
		<div>
			<Type.Headline4
				as={'p'}
				className={styles.graph_header}
			>
				That's up to 15 days of extra sleep per year
			</Type.Headline4>

			<div className={styles.graph_grid}>
				{props.data.map((bar) => (
					<Bar
						key={bar.label}
						label={bar.label}
						row={bar.row}
						endColumn={bar.endColumn}
						benefit={bar.benefit}
						highlighted={bar === highlightedBar}
					/>
				))}

				<GridBorders
					numColumns={numColumns}
					numRows={numRows}
				/>

				<div
					className={styles.graph_buttons}
					style={{
						gridColumnStart: 2,
						gridColumnEnd: numColumns + 1,
						gridRow: numRows + 1,
					}}
				>
					{props.data.map((bar, index) => (
						<Button.Empty
							id={`graph_button_${bar.buttonLabel}`}
							key={bar.buttonLabel}
							className={cx(styles.graph_button, { [styles.graph_button_highlighted]: index === highlighted })}
							onClick={() => setHighlighted(index)}
						>
							{bar.buttonLabel}
						</Button.Empty>
					))}
				</div>

				{props.data.map((bar, index) => (
					<div
						className={styles.graph_button_mobile}
						style={{
							gridColumnStart: 2,
							gridColumnEnd: numColumns + 1,
							gridRow: index + 1,
						}}
						key={`graph-wrapper-${index}`}
					>
						<Button.Empty
							id={`graph_button_mobile_${bar.buttonLabel}`}
							key={bar.buttonLabel}
							className={cx(styles.graph_button, { [styles.graph_button_highlighted]: index === highlighted })}
							onClick={() => setHighlighted(index)}
						>
							<Type.Body2 as={'span'}>{bar.buttonLabel}</Type.Body2>
						</Button.Empty>
					</div>
				))}

				<Type.SmallPrint
					className={styles.disclaimer}
					style={{
						gridColumnStart: 2,
						gridColumnEnd: numColumns + 1,
						gridRow: numRows + 2,
					}}
				>
					* Individual results may vary, and the extent of improvement in sleep cannot be guaranteed. Factors such as lifestyle, pre-existing medical conditions, and adherence to product usage
					instructions can influence outcomes.
				</Type.SmallPrint>
			</div>
		</div>
	)
}

const GridBorders = ({ numRows = 3, numColumns = 5 }) => {
	return (
		<>
			{Array.from({ length: numColumns }).map((_, index) => (
				<div
					className={styles.grid_border_right}
					style={{
						gridColumn: index + 1,
						gridRow: `1 / ${numRows + 1}`,
					}}
					key={`graph-borddr-${index}`}
				/>
			))}
			<div
				className={styles.grid_border_bottom}
				style={{
					gridColumn: `2 / ${numColumns + 1}`,
					gridRow: numRows,
				}}
			/>
			<div
				className={styles.grid_border_mobile_left}
				style={{
					gridColumn: 1,
					gridRow: `1 / ${numRows + 1}`,
				}}
			></div>
		</>
	)
}

const Bar: FC<Partial<BarProps>> = (props) => {
	return (
		<>
			<Type.Eyebrow2
				className={styles.time}
				style={{
					gridColumn: '1',
					gridRow: `${props.row}`,
				}}
			>
				{props.label}
			</Type.Eyebrow2>
			<div
				className={cx(styles.bar, { [styles.bar_highlighted]: props.highlighted })}
				style={{
					gridRow: `${props.row}`,
					gridColumn: `2 / ${props.endColumn + 2}`,
				}}
			/>
			<Type.Eyebrow2
				className={styles.benefit}
				style={
					{
						gridRow: `${props.row}`,
						'--end-col-desktop': props.endColumn + 2,
						'--end-col-mobile': 4,
						opacity: props.highlighted ? 1 : 0.25,
					} as CSSProperties
				}
			>
				+ {props.benefit}
			</Type.Eyebrow2>
		</>
	)
}
