import { BenefitProps } from 'components/_utils/utilityTypes'

export const getDefaultBenefits = (): BenefitProps[] => {
	return [
		{
			icon: 'CoolLight',
			text: 'Warms and cools automatically',
		},
		{
			icon: 'SnoringLight',
			text: 'Reduces snoring in real-time',
		},
		{
			icon: 'MattressLight',
			text: 'Fits onto your existing mattress',
		},
	]
}
