import { BarProps } from './types'

export const getNumColumns = (data: BarProps[]) => {
	return (
		data.reduce((acc, curr) => {
			return Math.max(acc, curr.endColumn)
		}, 0) + 2
	)
}

export const getNumRows = (data: BarProps[]) => {
	return data.reduce((acc, curr) => {
		return Math.max(acc, curr.row)
	}, 0)
}

export const getGraphData = () => {
	const GraphData: BarProps[] = [
		{
			label: '1 year',
			row: 1,
			endColumn: 3,
			benefit: '15 days',
			buttonLabel: 'Year',
		},
		{
			label: '1 month',
			row: 2,
			endColumn: 2,
			benefit: '30.4 hours',
			buttonLabel: 'Month',
		},
		{
			label: '1 day',
			row: 3,
			endColumn: 1,
			benefit: '1 hour',
			buttonLabel: 'Day',
		},
	]

	return GraphData
}
