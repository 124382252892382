import { useEffect } from 'react'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

/**
 * Hook that will refresh the ScrollTrigger when the provided ref is intersecting.
 * This is useful for the interim period where we have images without defined
 * dimensions, and so the ScrollTrigger needs to be refreshed when the image is loaded.
 */
export const useRefreshScrollTrigger = (ref: any) => {
	useEffect(() => {
		const intersectionObserver = new IntersectionObserver((entries) => {
			if (entries[0]?.isIntersecting) {
				ScrollTrigger.refresh()
			}
		})
		intersectionObserver.observe(ref.current)
		return () => {
			intersectionObserver.disconnect()
		}
	}, [])
}
